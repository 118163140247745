@import 'styles/includes.scss';

.HeroCard {
    $root: &;

    margin-bottom: 40px;

    @include media(m) {
        margin-bottom: 80px;
    }

    &__Container {
        @extend %container;
    }

    &__Wrapper {
        display: flex;
        flex-wrap: wrap;
        @include media(m) {
            flex-wrap: no-wrap;
            margin-right: -24px;
        }
    }

    &__Card {
        background-color: $colorYellow;
        transition: background-color $duration $ease;

        width: 100%;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;

        border-radius: $cardBorderRadius;

        @include media(m) {
            width: calc(100% / 3 - 24px);
            height: auto;
            margin-bottom: 0;
            margin-right: 24px;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    &__Title {
        @extend %standard-link-small;
        text-decoration: none;
        color: $colorBlack70;
        padding: 14px 24px;
        font-weight: 600;

        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:after {
            content: '';
            display: block;
            background-color: rgba(0, 0, 0, 0.12);
            margin-top: 10px;
            height: 1px;
        }

        &--Icon {
            span {
                padding-left: 28px;
            }

            &:before {
                content: '';
                display: block;
                width: 16px;
                height: 16px;

                position: absolute;
                left: 24px;
                top: 18px;

                background-size: contain;
                background-repeat: no-repeat;
            }
        }

        &--Icon-MapMarker:before {
            background-image: url('#{$basepath}svg/icon-map-marker.svg');
        }

        &--Icon-Explore:before {
            background-image: url('#{$basepath}svg/icon-explore.svg');
        }

        &--Icon-Info:before {
            width: 12px;
            height: 12px;

            background-image: url('#{$basepath}svg/icon-info.svg');
        }
    }

    &__Content {
        padding: 16px 24px;
        @extend %navigation-sub-titles;

        @include media(m) {
            font-size: 1.4rem;
        }

        @include media(tabletL) {
            font-size: 1.6rem;
        }
    }

    &__Link {
        @extend %navigation-sub-titles;
        display: inline-flex;
        align-self: flex-start;
        align-items: center;
        width: auto;

        @include media(m) {
            font-size: 1.4rem;
        }

        @include media(tabletL) {
            font-size: 1.6rem;
        }

        margin-bottom: 12px;
        text-decoration: none;

        color: $colorBlack70;
        &:hover {
            color: $colorBlack;
        }

        &:focus {
            outline: none;
            color: $colorBlack;
            background-color: $colorYellow08;
        }

        transition: color $duration $ease, background-color $duration $ease;
    }

    &__Icon {
        flex-shrink: 0;
        display: inline-block;
        height: 16px;
        width: 16px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 8px;

        color: $colorBlack70;

        &--Arrow {
            padding-top: 4px;
            background-image: url('#{$basepath}svg/icon-circle-arrow.svg');
        }
    }

    &__ButtonWrapper {
        position: relative;
        display: block;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        padding: 11px 16px;
        text-align: right;
        margin-top: auto;
    }

    &__Button {
        @extend %navigation-titles;

        position: relative;
        width: auto;
        height: 100%;
        border-radius: 3px;

        padding: 8px;

        color: $colorBlack;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: normal;

        transition: background-color $duration $ease;

        &:hover {
            background-color: $colorYellow08;
        }

        &:focus {
            background-color: $colorYellow12;
            outline: none;
        }
    }
}
